<template>
    <b-card>
      <ValidationObserver>
        <form @submit.prevent="saveItem">
          <b-row>
            <b-col cols="12" md="6">
              <div class="form-group">
                <label for="title">Title:</label>
                <validation-provider
                  name="title"
                  rules="required"
                  v-slot="{ errors, classes }"
                >
                  <input
                    id="title"
                    type="text"
                    class="form-control"
                    :class="classes"
                    :state="errors.length > 0 ? false : null"
                    v-model="formPayload.title"
                    placeholder="Judul"
                  />
                  <small
                    v-for="(validation, index) in validations.title"
                    :key="`errorName${index}`"
                    class="text-danger"
                    >{{ validation }}</small
                  >
                </validation-provider>
              </div>
              <div class="form-group">
                <label for="category">Category:</label>
                <br />
                <small>Jika tidak ada dalam daftar silahkan ketik:</small>
                <validation-provider name="category" rules="required">
                  <v-select
                    id="group"
                    label="name"
                    v-model="formPayload.category"
                    :options="categories"
                    placeholder="-- Pilih --"
                    :reduce="(categories) => categories.uuid"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    @keyup.stop.native="searchCategories"
                  />
                </validation-provider>
              </div>
              <div class="form-group">
                <label for="instructor">Instructor:</label>
                <br />
                <small>Jika tidak ada dalam daftar silahkan ketik:</small>
                <validation-provider name="category" rules="required">
                  <v-select
                    id="group"
                    label="name"
                    v-model="formPayload.instructor"
                    :options="instructor"
                    placeholder="-- Pilih --"
                    :reduce="(instructor) => instructor.uuid"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    @keyup.stop.native="searchInstructor"
                  />
                </validation-provider>
              </div>
              <div class="form-group">
                <label for="type"> Type: </label>
                <validation-provider name="type" rules="required">
                  <v-select
                    id="type"
                    label="title"
                    v-model="formPayload.type"
                    :options="optionType"
                    placeholder="-- Pilih --"
                    :reduce="(option) => option.value"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  />
                  <small
                    v-for="(validation, index) in validations.type"
                    :key="`errorName${index}`"
                    class="text-danger"
                    >{{ validation }}</small
                  >
                </validation-provider>
              </div>
              <div class="form-group">
                <label for="class_type"> Class Type: </label>
                <validation-provider name="class_type" rules="required">
                  <v-select
                    id="class_type"
                    label="title"
                    v-model="formPayload.class_type"
                    :options="optionClassType"
                    placeholder="-- Pilih --"
                    :reduce="(option) => option.value"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  />
                  <small
                    v-for="(validation, index) in validations.class_type"
                    :key="`errorName${index}`"
                    class="text-danger"
                    >{{ validation }}</small
                  >
                </validation-provider>
              </div>
              <div class="form-group">
                <label for="status"> Status: </label>
                <validation-provider name="status" rules="required">
                  <v-select
                    id="status"
                    label="title"
                    v-model="formPayload.status"
                    :options="option"
                    placeholder="-- Pilih Status --"
                    :reduce="(option) => option.value"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  />
                  <small
                    v-for="(validation, index) in validations.status"
                    :key="`errorName${index}`"
                    class="text-danger"
                    >{{ validation }}</small
                  >
                </validation-provider>
              </div>
              <div class="form-group">
                <label for="content_url">Url Materi:</label>
                <validation-provider
                  name="content_url"
                  rules="required"
                  v-slot="{ errors, classes }"
                >
                  <input
                    id="content_url"
                    type="text"
                    class="form-control"
                    :class="classes"
                    :state="errors.length > 0 ? false : null"
                    v-model="formPayload.content_url"
                    placeholder="URL"
                  />
                  <small
                    v-for="(validation, index) in validations.content_url"
                    :key="`errorName${index}`"
                    class="text-danger"
                    >{{ validation }}</small
                  >
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12" md="6">
              <div class="form-group">
                <label for="description">Description:</label>

                <validation-provider
                  name="description"
                  rules="required"
                  v-slot="{ errors, classes }"
                >
                  <textarea
                    v-model="formPayload.description"
                    id="description"
                    class="form-control"
                    cols="30"
                    rows="10"
                    maxlength="2500"
                    :class="classes"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small
                    v-for="(validation, index) in validations.description"
                    :key="`errorName${index}`"
                    class="text-danger"
                    >{{ validation }}</small
                  >
                </validation-provider>
              </div>
              <div class="form-group" v-if="fileContent == 'file'">
                <label for="">Image:</label><br />
                <p class="image__hint"><i>Max 2 mb</i></p>
                <validation-provider name="image" rules="required">
                  <b-form-file
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    @change="changeFile($event)"
                  />
                  <small>Current file:</small><br />
                  <img
                    v-if="
                      (file_url !== null && file_type == 'image') ||
                      file_type == 'jpeg' ||
                      file_type == 'png' ||
                      file_type == 'jpg'
                    "
                    :src="file_url"
                    class="media_file"
                  />
                  <small class="text-danger">{{ validationsFile }}</small>
                </validation-provider>
              </div>
            </b-col>
          </b-row>
          <b-col cols="12" class="p-0 mt-2">
            <button
              variant="primary"
              class="btn waves-effect waves-float waves-light btn-primary"
              :disabled="isLoading"
            >
              {{ isLoading ? "Submitting..." : "Submit" }}
            </button>
            &nbsp; &nbsp;
            <a
              @click="handleBackButton()"
              variant="secondary"
              class="btn waves-effect waves-float waves-light btn-secondary"
            >
              Cancel
            </a>
          </b-col>
        </form>
      </ValidationObserver>
    </b-card>
  </template>
  
<script>
  import flatPickr from "vue-flatpickr-component";
  import moment from "moment";
  import vSelect from "vue-select";
  import { quillEditor } from "vue-quill-editor";
  import _ from "lodash";
  import {
    BCard,
    BFormGroup,
    BFormRadio,
    BRow,
    BCol,
    BFormFile,
    BFormCheckbox,
  } from "bootstrap-vue";
  import {
    ValidationProvider,
    ValidationObserver,
    configure,
  } from "vee-validate";
  import { required } from "@validations";
  import { successNotification, errorNotification } from "@/auth/utils";
  configure({
    classes: {
      valid: "is-valid",
      invalid: "is-invalid",
      dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
      // ...
    },
  });
  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BFormGroup,
      BFormRadio,
      BFormCheckbox,
      vSelect,
      quillEditor,
      BFormFile,
      flatPickr,
      ValidationProvider,
      ValidationObserver,
    },
  
    setup() {
      return {
        successNotification,
        errorNotification,
      };
    },
  
    data() {
      return {
        required,
        moment,
        isLoading: false,
        formPayload: {
          title: "",
          category: "",
          instructor: "",
          content: "",
          youtube_id: "",
          content_file: "",
          content_url: "",
          image: "",
          description: "",
          status: "publish",
          type: "general",
          class_type: "series",
          content_url: "",
        },
        option: [
          { title: "Publish", value: "publish" },
          { title: "Unpublish", value: "unpublish" },
        ],
        optionType: [
          { title: "General", value: "general" },
          { title: "Competition", value: "competition" },
          { title: "Financial", value: "financial" },
          { title: "Female Future Leader", value: "female-future-leader" },
          
        ],
        optionClassType: [
          { title: "Series", value: "series" },
          { title: "Single", value: "single" },
        ],
        file_url: null,
        file_type: "",
        fileContent: "file",
        categories: [],
        instructor: [],
        validations: "",
        validationsFile: "",
        // Must be an array reference!
      };
    },
  
    mounted() {
      this.loadCategory();
      this.loadInstructor();
    },
  
    methods: {
      handleBackButton() {
        history.back();
      },
      changeFile(event) {
        this.formPayload.image = event.target.files[0];
        this.showFile(event.target.files[0]);
      },
      showFile(file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.file_url = e.target.result;
          var str = e.target.result;
          var startIndex = str.indexOf("/") + 1; // Menentukan indeks mulai setelah karakter '/'
          var endIndex = str.indexOf(";"); // Menentukan indeks akhir sebelum karakter ';'
          var imageType = str.substring(startIndex, endIndex); // Memotong string berdasarkan indeks mulai dan akhir
          this.file_type = imageType;
        };
        reader.readAsDataURL(file);
      },
      searchCategories: _.debounce(function (e) {
        const search = e.target.value;
        this.loadCategory(search);
      }, 300),
      loadCategory(search) {
        this.$http
          .get("/api/v1/admin/e-learning-category", {
            params: {
              search: search != null ? search : null,
            },
          })
          .then((response) => {
            this.categories = response.data.data.items;
          });
      },
      searchInstructor: _.debounce(function (e) {
        const search = e.target.value;
        this.loadInstructor(search);
      }, 300),
      loadInstructor(search) {
        this.$http
          .get("/api/v1/admin/users", {
            params: {
              search: search != null ? search : null,
              group: 'instructor'
            },
          })
          .then((response) => {
            this.instructor = response.data.data.items;
          });
      },
      saveItem() {
        this.isLoading = true;
        this.validations = "";
        this.validationsFile = "";
        const payload = new FormData();
        payload.append("title", this.formPayload.title);
        payload.append("type", this.formPayload.type);
        payload.append("class_type", this.formPayload.class_type);
        payload.append("category", this.formPayload.category);
        payload.append("instructor", this.formPayload.instructor);
        payload.append("image", this.formPayload.image);
        payload.append("description", this.formPayload.description);
        payload.append("status", this.formPayload.status);
        payload.append("content_url", this.formPayload.content_url);
        this.$http
          .post("/api/v1/admin/e-learning", payload, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response) => {
            this.isLoading = false;
            successNotification(this, "Success", "E-Learning Berhasil dibuat!");
            this.$router.push({ name: "e-learning" });
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response.data.meta.validations) {
              this.validations = error.response.data.meta.validations;
              errorNotification(this, "Title", this.validations.title.join(""));
              errorNotification(this, "Category", this.validations.category.join(""));
              errorNotification(this, "Instructor", this.validations.instructor.join(""));
              errorNotification(this, "Description", this.validations.description.join(""));
            } else if (error.response.data.meta.messages) {
              this.validationsFile = error.response.data.meta.messages;
              errorNotification(this, "Input kosong!", this.validationsFile);
            }
          });
      },
    },
  };
</script>
  
<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.image__hint {
  font-size: 10px;
  margin-bottom: 0;
}
.media_file {
  width: 100%;
  height: 300px;
  object-fit: contain;
}
</style>
  